<template>
    <router-link :to="{ name: link_name }" :class="{ 'mobile-menu-item' : !active}">
        <button v-if="active" class="btn-a">
            <span class="material-icons align-middle">{{ icon }}</span>
            {{ title }}
        </button>
        <span v-else class="material-icons el-center">{{ icon }}</span>
    </router-link>
</template>

<script>
export default {
    name: "ItemMobileMenu",
    props: {
        active: Boolean,
        title : String,
        icon : String,
        link_name : String,
    },
}
</script>

<style scoped>
button{
    /*border-radius: px;*/
}
a{
    display: flex;
    align-items: center;
    margin: auto;
}
</style>
