<template>
  <div v-if="comments.length > 0">
    <div class="text-center">
      <h3 class="title-border-buttom">
        <lottie-circle></lottie-circle>
        نظرات مشتریان ما
      </h3>
    </div>

    <swiper class="swiper" :options="swiperOption">
      <div
        class="swiper-slide"
        v-for="(comment, index) in comments"
        :key="index"
      >
        <div
          class="img-user-comented"
          :class="{ show: !index }"
          :data-index="index"
          @click="active_comment"
          :style="{ backgroundImage: 'url(' + comment.image + ')' }"
        >
          <div class="pointer"></div>
        </div>
        <p class="text-center fw-bold fs-13">{{ comment.user_name }}</p>
      </div>
    </swiper>

    <div class="alert alert-light bg-light px-md-5">
      <div class="mx-md-5 position-relative text-justify">
        <span class="duble-cot-right">"</span>
        {{ selectedItem.text }}
        <span class="duble-cot-left">"</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import LottieCircle from "./LottieCircle";

export default {
  components: {
    LottieCircle,
    Swiper,
    // SwiperSlide,
  },
  created() {
    this.selectedItem = this.comments[0];
  },
  data() {
    return {
      selectedItem: null,
      swiperOption: {
        centeredSlides: true,
        slidesPerView: 3,
        spaceBetween: 30,
        breakpoints: {
          400: {
            slidesPerView: 4,
          },
          576: {
            slidesPerView: 5,
          },
          768: {
            centeredSlides: false,
            slidesPerView: 6,
          },
        },
      },
      comments: [
        {
          user_name: "مدیر پتروشیمی",
          image: "/images/mohsen.jpg",
          text: "از ابتدای مسیر این شرکت بسیار قدرتمند در زمینه خدمات آنلاین مصالح ساختمانی افتخار همکاری با آنها را دارم و در طی این تجربه با ارزش تمامی نیاز های شرکت خود را تامین کرده ام. سهولت در استفاده، گستردگی در خدمات و پشتیبانی 24 ساعته در .طول هفت رو هفته از ویژگی های مورد علاقه من است",
        },
        {
          user_name: "سازنده با سابقه",
          image: "/images/mohsen.jpg",
          text: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود .ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده است",
        },
        {
          user_name: "مدیر آپادیس",
          image: "/images/mohsen.jpg",
          text: "از ابتدای مسیر این شرکت بسیار قدرتمند در زمینه خدمات آنلاین مصالح ساختمانی افتخار همکاری با آنها را دارم و در طی این تجربه با ارزش تمامی نیاز های شرکت خود را تامین کرده ام. سهولت در استفاده، گستردگی در خدمات و پشتیبانی 24 ساعته در .طول هفت رو هفته از ویژگی های مورد علاقه من است",
        },
        {
          user_name: "مدیر اجرایی",
          image: "/images/mohsen.jpg",
          text: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود .ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده است",
        },
        {
          user_name: "مهندس ناظر",
          image: "/images/mohsen.jpg",
          text: "از ابتدای مسیر این شرکت بسیار قدرتمند در زمینه خدمات آنلاین مصالح ساختمانی افتخار همکاری با آنها را دارم و در طی این تجربه با ارزش تمامی نیاز های شرکت خود را تامین کرده ام. سهولت در استفاده، گستردگی در خدمات و پشتیبانی 24 ساعته در .طول هفت رو هفته از ویژگی های مورد علاقه من است",
        },
        {
          user_name: "مدیر شرکت سیمان قائم",
          image: "/images/mohsen.jpg",
          text: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود .ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده است",
        },
      ],
    };
  },
  methods: {
    active_comment: function (event) {
      Array.from(document.getElementsByClassName("img-user-comented")).map(
        (x) => x.classList.remove("show")
      );
      event.target.classList.add("show");
      this.selectedItem =
        this.comments[event.target.getAttribute("data-index")];
    },
  },
};
</script>
