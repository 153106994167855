<template>
    <div class="form_group">
        <textarea
                  rows="3"
                  @input="updateValue($event.target.value)"
                  autocomplete="off"
                  :name="name"
                  :placeholder="placeholder"
                  :id="IdUnique">{{ value }}</textarea>

        <label :for="IdUnique" class="form_label">{{ placeholder }}</label>
    </div>
</template>

<script>
export default {

    name: "TextAreaAyandeh",
    data() {
        return {
            IdUnique : 1,
            content: this.model,
        }
    },
    $_veeValidate:{
        value(){
            return this.$el.value;
        },
        name(){
            return this.name;
        }
    },
    props: {
        placeholder: {
            default: '',
            type: String,
        },
        value: String|Number,
        name: String,
    },
    methods: {
        updateValue(value){
            this.$emit('input', value);
        }
    },
    mounted(){
        this.IdUnique = 'input' + this._uid;
    }
}
</script>


