<template>
  <!--    Desktop Navbar-->
  <div class="w-100" style="position: fixed; top: 0; left: 0; z-index: 5">
    <b-navbar
      class="bd-header main-header navbar-expand-md d-none d-md-flex justify-content-between p-0"
    >
      <router-link
        :to="{ name: 'Home' }"
        class="navbar-brand d-inline-block mr-md-4"
      >
        <img
          src="./../../assets/images/logo/logo.svg"
          alt="سیمان آینده"
          height="60px"
        />
      </router-link>
      <div id="navbarCollapse" class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item d-flex flex-column justify-content-center px-4">
            <button v-b-modal="'my-modal'" class="btn-transparent">
              حساب کاربری
              <span class="material-icons-outlined align-middle"
                >account_circle</span
              >
            </button>
          </li>
          <li
            class="nav-item contacts d-flex flex-column justify-content-center px-4"
          >
            <div class="mt-1">
              <span
                class="material-icons align-middle text-white b-circle fs-14"
                >call</span
              >
              <a href="tel:02191300630" class="ml-1">{{ "02191300630" }}</a>
              <span
                class="material-icons align-middle text-white b-circle fs-14 ml-4 mr-1"
                >email</span
              >
              <span class="d-inline-block text-white">{{
                "info@ayandehcement.com"
              }}</span>
            </div>
          </li>
        </ul>
      </div>
    </b-navbar>

    <b-navbar class="main-header d-md-none justify-content-between">
      <!-- <span class="material-icons menu-mobile-icon">menu</span> -->

      <router-link :to="{ name: 'Home' }" class="navbar-brand d-inline-block">
        <img
          src="./../../assets/images/logo/logo.svg"
          alt="سیمان آینده"
          height="59px"
        />
      </router-link>

      <!-- <div
        v-if="false"
        ref="userMobileMenu"
        class="user-menu-mobile"
        :class="{ open: userMenuToggle, closed: user_menu_cloded }"
      >
        <div class="user-menu-header row">
          <b-col cols="12" class="p-0" align-self="start">
            <span
              class="material-icons close_menu"
              @click="user_menu_open = false"
              >arrow_back</span
            >
          </b-col>
          <b-col cols="12" align-self="end">
            <p class="user-menu-name">{{ fullname }}</p>
          </b-col>
        </div>
        <span
          class="avatar-mobile"
          :style="{ 'background-image': urlAvatar }"
          @click="user_menu_open = true"
        ></span>

        <div class="user-menu-content">
          <div class="text-right mb-3">
            <span class="material-icons material-bg-yellow text-blue-dark ml-1"
              >account_balance_wallet</span
            >
            <span class="text-purple">موجودی کیف پول</span>
            <span class="float-left"
              >{{ wallet_balance | numberFormat }} {{ currency }}</span
            >
          </div>
          <div class="text-right mb-5">
            <span class="material-icons material-bg-yellow text-blue-dark ml-1"
              >query_builder</span
            >
            <span class="text-purple">دارایی های شما</span>
            <span class="float-left">{{ countProductUser() }} محصول</span>
          </div>

          <div class="text-right">
            <router-link
              :to="{ name: 'Dashboard' }"
              class="d-block py-2 text-blue-dark"
            >
              <span
                class="material-icons-outlined align-middle ml-1 text-gray-2"
                >manage_accounts</span
              >
              پروفایل کاربری
            </router-link>
            <router-link :to="{ name: '' }" class="d-block py-2 text-blue-dark">
              <span class="material-icons align-middle ml-1 text-gray-2"
                >storefront</span
              >
              لیست فروش
            </router-link>
            <router-link
              :to="{ name: 'BuyHistory' }"
              class="d-block py-2 text-blue-dark"
            >
              <span
                class="material-icons-outlined align-middle ml-1 text-gray-2"
                >work_history</span
              >
              سوابق خرید
            </router-link>
            <router-link :to="{ name: '' }" class="d-block py-2 text-blue-dark">
              <span
                class="material-icons-outlined align-middle ml-1 text-gray-2"
                >local_shipping</span
              >
              اعلام بار
            </router-link>
          </div>

          <p class="logout text-danger" @click="logout">خروج از حساب کاربری</p>
        </div>
      </div> -->
      <div class="text-white" v-b-modal="'my-modal'">
        <span class="material-icons align-middle">login</span>ورود
      </div>
      <div
        class="backdrop_menu"
        :class="{ show: userMenuToggle }"
        @click="user_menu_open = false"
      ></div>
    </b-navbar>

    <breadcrumb v-if="$store.state.breadCrumbItems"></breadcrumb>

    <b-modal id="my-modal" size="lg" centered hide-footer>
      <template #modal-title> ورود به پنل </template>
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between my-3"
      >
        <div class="panel-box">
          <h5 class="text-center mb-3">پنل کاربری</h5>
          <div
            class="image-box position-relative"
            style="background-image: url(/images/new-panel.png)"
            @mouseover="showNewPanelLink = true"
            @mouseleave="showNewPanelLink = false"
          >
            <div
              v-if="showNewPanelLink"
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded"
              style="background-color: rgba(0, 0, 0, 0.2)"
            >
              <b-button
                variant="primary"
                href="https://panel.ayandehcement.com/"
                style="height: 40px"
                >ورود به پنل کاربری</b-button
              >
            </div>
          </div>
        </div>
        <div class="panel-box mt-5 mt-md-0">
          <h5 class="text-center mb-3">پنل باربری</h5>
          <div
            class="image-box position-relative"
            style="background-image: url(/images/old-panel.png)"
            @mouseover="showOldPanelLink = true"
            @mouseleave="showOldPanelLink = false"
          >
            <div
              v-if="showOldPanelLink"
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded cursor-pointer"
              style="background-color: rgba(0, 0, 0, 0.2)"
              @click="goToOldPanel()"
            >
              <b-button
                variant="primary"
                href="https://admin.ayandehcement.com/"
                style="height: 40px"
                >ورود به پنل باربری</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapActions, mapGetters } from "vuex";
// import Cart from "@/components/Cart";
import Notifications from "@/components/Notifications";

export default {
  components: { Breadcrumb },
  data() {
    return {
      setTimeOut: null,
      user_menu_open: false,
      user_menu_cloded: false,
      showNewPanelLink: false,
      showOldPanelLink: false,
    };
  },
  watch: {
    user_menu_open(newValue) {
      if (newValue == false) {
        this.user_menu_cloded = true;
        var thisOb = this;
        this.setTimeOut = setTimeout(function () {
          thisOb.user_menu_cloded = false;
        }, 500);
      }
    },
  },
  methods: {
    ...mapActions(["storeUserInfo", "openCart", "openNotifications"]),
    ...mapGetters(["cartUser", "userNotifications", "countProductUser"]),
    onCopy() {
      this.myToast("ایمیل کپی شد!");
    },
    goToOldPanel() {
      window.location.assign("https://opanel.ayandehcement.com/");
    },
    goToNewPanel() {
      window.location.assign("https://panel.ayandehcement.com/");
    },
  },
  computed: {
    userMenuToggle() {
      if (this.user_menu_open) {
        this.setTimeOut = null;
        return true;
      } else {
        return false;
      }
    },
    cart() {
      return this.cartUser();
    },
    notifs() {
      return this.userNotifications();
    },
  },
};
</script>

<style scoped>
.panel-box {
  width: 45%;
  height: auto;
}

.image-box {
  width: 100%;
  min-height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 2px solid #0a3450;
}

@media screen and (max-width: 768px) {
  .panel-box {
    width: 100%;
  }
  .image-box {
    min-height: 150px;
  }
}
</style>
