<template>
    <div>
        <div class="card card_floating" :class="{'show': $store.state.notif_status}">
            <div class="card-header">
                اعلانات
                <span class="material-icons align-middle">notifications</span>
            </div>
            <div class="card-body">
                <template v-if="notifs.length == 0">
                    <lottie-vue-player
                        class="mt-5 mx-auto"
                        style="width: 100px"
                        :src="`/lottie-files/Noti.json`"
                        :autoplay="true"
                        :loop="true"/>

                    <p class="mt-4 text-center">شما اعلانی دریافت نکرده اید</p>
                </template>

                <ul class="cart_body">
                    <li class="cart_item" v-for="cart_irem in notifs">

                    </li>
                </ul>
            </div>
        </div>

        <div class="backdrop_card" @click="closeNotifications" :class="{'show': $store.state.notif_status}"></div>

    </div>
</template>

<script>

import {mapGetters,mapActions} from 'vuex';
export default {
    name: "Notifications",
    methods:{
        ...mapGetters([
            'userNotifications'
        ]),
        ...mapActions([
            'closeNotifications'
        ])
    },
    computed:{
        notifs(){
            return this.userNotifications();
        }
    }
}
</script>
