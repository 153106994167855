<template>
    <div class="form__group field" :class="bodyClass" :style="bodyStyle">
        <span class="material-icons showPass"
              @click="toggleShowPass"
              v-if="type == 'password'">visibility</span>

        <input class="form__field"
               :placeholder="placeholder"
               :class="inputClass"
               :value="value"
               ref="input"
               @input="initInput($event.target.value)"
               @blur="$emit('blur')"
               autocomplete="off"
               v-mask="mask"
               :name="name"
               :type="type"
               :disabled="disabled"
               :id="IdUnique"/>
        <label :for="IdUnique" class="form__label">{{ placeholder }}</label>
    </div>
</template>

<script>


export default {
    name: "InputAyandeh",
    $_veeValidate: {
        value() {
            return this.$el.value;
        },
        name() {
            return this.name;
        }
    },
    data() {
        return {
            value: null,
            IdUnique: 1,
        }
    },
    props: {
        placeholder: {
            default: '',
            type: String,
        },
        bodyClass: String,
        bodyStyle: String,
        inputClass: String,
        type: {
            default: 'text',
            type: String,
        },
        name: String,
        autofocus: {
            default: false,
            type: Boolean
        },
        minlength: {
            default: null,
            type: Number
        },
        maxlength: {
            default: null,
            type: Number
        },
        maxVal: {
            default: null,
            type: Number
        },
        mask: String,
        valueProp: null,
        numberFormat: false,
        unit_value: {
          default: 'ریال',
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        value(newValue, oldValue) {
            if(newValue){
                var numberic_value = parseInt(newValue.toString().replaceAll(/[^0-9]/g,''));
            }

            if (this.maxlength && newValue.length > this.maxlength) {
                this.value = numberic_value.substring(0, this.maxlength);
            }

            if (this.maxVal && numberic_value > this.maxVal) {
                this.myToast('مقدار ورودی نباید بیشتر از ' + this.toNumberFormat(this.maxVal) + ' ' + this.unit_value + ' باشد', 'error');
                this.value = oldValue;
                return false;
            }

            if (this.numberFormat) {
                this.value = this.toNumberFormat(newValue);
                this.$emit('input', numberic_value);
            }else{
                this.$emit('input', newValue);
            }
        }
    },
    methods: {
        initInput(val) {
            this.value = val;
        },
        toggleShowPass(e) {
            var type = this.$refs.input.type;
            if (type == 'text') {
                this.$refs.input.type = 'password';
                e.target.innerHTML = 'visibility';
            } else {
                this.$refs.input.type = 'text';
                e.target.innerHTML = 'visibility_off';
            }
        }
    },
    mounted() {
        this.IdUnique = 'input' + this._uid;
        this.$refs.input.value = this.value;

        if (this.valueProp) {
            this.value = this.valueProp;
        }

        if (this.autofocus) {
            setTimeout(() => {
                this.$refs.input.focus();
            }, 200);
        }
    },
    computed: {
        getRef() {
            return this.refs ? this.refs : this.IdUnique;
        }
    }
}
</script>

