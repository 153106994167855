<template>
  <b-container class="pt-5">
    <b-row>
      <b-col md="12" class="mb-3">
        <div class="text-right mb-5">
          <h2 class="yekanExBold fs-16 d-inline-block">
            اطلاعات تماس سیمان آینده
          </h2>
        </div>
        <div class="position-relative mr-70 mb-5">
          <div class="square_number_title">
            <span class="material-icons">phone</span>
            <lottie-circle></lottie-circle>
          </div>
          <div class="d-inline-block">
            <p class="text-left mb-0">
              <a
                href="tel:05435313317"
                style="color: #2e384d; margin-right: 4px"
                >05435313317</a
              >
              -
              <a
                href="tel:02191306630"
                style="color: #2e384d; margin-right: 4px"
                >02191306630</a
              >
            </p>
            <p class="text-left mb-2">
              <a
                href="tel:09120709976"
                style="color: #2e384d; margin-right: 4px"
                >09120709976</a
              >
            </p>
          </div>
        </div>
        <div class="position-relative mr-70" style="margin-bottom: 48px">
          <div class="square_number_title">
            <span class="material-icons">place</span>
            <lottie-circle></lottie-circle>
          </div>
          <div>
            <p class="mb-0 fs-12">
              دفتر مرکزی: تهران بزرگراه خرازی، برج تریتا، طبقه شش، سیمان آینده
            </p>
          </div>
        </div>
        <div class="position-relative mr-70" style="margin-bottom: 48px">
          <div class="square_number_title">
            <span class="material-icons">place</span>
            <lottie-circle></lottie-circle>
          </div>
          <div>
            <p class="mb-0 fs-12">
              دفتر چابهار: سیستان و بلوچستان، چابهار، منطقه آزاد، نبش میدان
              کارگر، مجتمع بیزینس سنتر چابهار، طبقه اول، واحد 102
            </p>
          </div>
        </div>
        <div class="position-relative mr-70 mb-5">
          <div class="square_number_title">
            <span class="material-icons">mail</span>
            <lottie-circle></lottie-circle>
          </div>
          <div>
            <a
              :href="'mailto:' + contacts.email + '?Subject=تماس با آینده'"
              class="d-block d-md-none"
            >
              <button class="btn-email">info@ayandehcement.com</button>
            </a>
            <button
              class="btn-email d-none d-md-block"
              v-clipboard:copy="contacts.email"
              v-clipboard:success="onCopy"
            >
              info@ayandehcement.com
            </button>
          </div>
        </div>
      </b-col>

      <!-- <b-col md="6">
        <form @submit.stop.prevent="submitForm">
          <b-card
            header="فرم تماس با ما"
            class="custom-shadow"
            header-class="bg-white"
            footer-class="text-left bg-white"
          >
            <b-row>
              <b-col cols="6" class="mb-3 pl-2">
                <input-ayandeh
                  placeholder="نام و نام خانوادگی"
                  v-validate="'required'"
                  name="fullname"
                  v-model="parameters.fullname"
                >
                </input-ayandeh>
                <small class="text-danger" v-show="errors.has('fullname')">{{
                  errors.first("fullname")
                }}</small>
              </b-col>
              <b-col cols="6" class="mb-3 pr-2">
                <input-ayandeh
                  placeholder="آدرس ایمیل"
                  v-validate="'required|email'"
                  name="email"
                  body-class="ltr"
                  v-model="parameters.email"
                >
                </input-ayandeh>
                <small class="text-danger" v-show="errors.has('email')">{{
                  errors.first("email")
                }}</small>
              </b-col>
              <b-col cols="6" class="mb-3 pl-2">
                <input-ayandeh
                  placeholder="موبایل(اختیاری)"
                  input-class="ltr"
                  v-validate="'numeric|min:11'"
                  name="mobile"
                  v-model="parameters.mobile"
                >
                </input-ayandeh>
              </b-col>
              <b-col cols="6" class="mb-3 pr-2">
                <input-ayandeh
                  placeholder="موضوع"
                  v-validate="'required'"
                  name="subject"
                  v-model="parameters.subject"
                >
                </input-ayandeh>
                <small class="text-danger" v-show="errors.has('subject')">{{
                  errors.first("subject")
                }}</small>
              </b-col>
              <b-col cols="12" class="mb-3">
                <text-area-ayandeh
                  placeholder="توضیحات"
                  name="desc"
                  v-validate="'required|min:10'"
                  v-model="parameters.desc"
                >
                </text-area-ayandeh>
                <small class="text-danger" v-show="errors.has('desc')">{{
                  errors.first("desc")
                }}</small>
              </b-col>
              <b-col cols="12">
                <div class="d-flex justify-content-start">
                  <captcha-code
                    :width="120"
                    :captcha.sync="code"
                    ref="captcha"
                  ></captcha-code>
                  <input
                    type="text"
                    class="input-easy ltr mr-3"
                    :class="{ 'border-red': errors.has('captcha') }"
                    v-validate="'required'"
                    name="captcha"
                    v-model="codeEntered"
                  />
                </div>
                <small class="text-danger" v-show="errors.has('captcha')">{{
                  errors.first("captcha")
                }}</small>
              </b-col>
            </b-row>
            <template #footer>
              <button disabled class="btn-a">ارسال</button>
            </template>
          </b-card>
        </form>
        <p class="mt-3">
          بخش
          <router-link :to="{ name: 'Faq' }">سوالات متداول</router-link>
          شما را در یافتن پاسخ سوال ها کمک می کند.
        </p>
      </b-col> -->
    </b-row>
  </b-container>
</template>

<script>
import LottieCircle from "./../components/LottieCircle";
import InputAyandeh from "./../components/form/InputAyandeh";
import TextAreaAyandeh from "./../components/form/TextAreaAyandeh";
// import CaptchaCode from "vue-captcha-code";

export default {
  name: "ContactUs",
  data() {
    return {
      code: "",
      codeEntered: null,
      parameters: {
        fullname: null,
        email: null,
        mobile: null,
        subject: null,
        desc: null,
      },
    };
  },
  components: { TextAreaAyandeh, InputAyandeh, LottieCircle },
  computed: {
    contacts() {
      return this.$store.state.contactUsDetail;
    },
  },
  methods: {
    onCopy() {
      this.myToast("ایمیل کپی شد!");
    },
    handleClick() {
      this.$refs.captcha.refreshCaptcha();
      this.codeEntered = this.code;
    },
    submitForm() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.codeEntered != this.code) {
            this.$custom.myToast("کد اعتبار سنجی وارد شده اشتباه است", "error");
            return false;
          }

          axios
            .post("/contact_us/store_contact", this.parameters)
            .then(({ data }) => {
              if (data.status == "success") {
                this.myAlert(
                  "اطلاعات با موفقیت ذخیره شد<br>با تشکر از تماس شما",
                  "success"
                );
                this.parameters = {
                  fullname: null,
                  email: null,
                  mobile: null,
                  subject: null,
                  desc: null,
                };
                this.codeEntered = null;
                this.errors.clear();
                this.$refs.captcha.refreshCaptcha();
              }
            });
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.mr-70 {
  margin-right: 70px;
}

.square_number_title {
  top: 50%;
  transform: translateY(-50%);
}

.w-100px {
  width: 100px;
}
</style>
