
export default {

    setBreadCrumb: ({ commit }, items) => {
        commit('setBreadCrumb', items);
    },

    // Form Shop
    setOrderCountBuy: ({ commit }, value) => {
        commit('setOrderCountBuy', value);
    },
    decreaseCount: ({ commit }) => {
        commit('decreaseCount');
    },
    increaseCount: ({ commit }) => {
        commit('increaseCount');
    },
    updateMinufactorer: ({commit}, TolidKonandedId) => {

        axios.get('/bourse/get_minufactorer/'+ TolidKonandedId)
            .then(({data}) => {
                if(data.status == 'success'){
                    commit('setMinufactorer', data.data);
                }
            })
            .catch(e => {
                console.log(e);
            });
    },
    setMinufactorer: ({ commit } ,value) => {
        commit('setMinufactorer', value);
    },

    // competition
    setCompetition: ({ commit }, value) => {
        commit('setCompetition', value);
    },

    // Set Contact Us Detail
    setContactUsDet: ({ commit }) => {

        axios.get('/contact_us/get_details')
            .then( ({ data }) => {
                if(data.status == 'success'){
                    commit('setContactUsDet', data.data);
                }
            })
    },

    // Authentication
    storeUserInfo: ({ commit },user = null) => {
        commit('storeUserInfo', user);
    },

    // Open Modal After Doing Success Any Level
    openModalAfterDoing: ({ commit },modal_id = null) => {
        commit('openModalAfterDoing', modal_id);
    },

    updateUserInfo: ({ commit }) => {
        axios.get('/user/get_user')
            .then( ({ data }) => {
                commit('storeUserInfo', data.user);
                commit('storeCartInfo', data.cart);
                commit('storeCountProduct', data.count_products);
            });
    },

    // Cart
    openCart: ({ commit }) => {
        commit('changeStatusCart', true);
    },
    closeCart: ({ commit }) => {
        commit('changeStatusCart', false);
    },

    // Notifications
    openNotifications: ({ commit }) => {
        commit('changeStatusNotifications', true);
    },

    closeNotifications: ({ commit }) => {
        commit('changeStatusNotifications', false);
    },

    // Bourse
    setCurrentArzeh: ({commit}, arzeh) => {
        commit('setCurrentArzeh',arzeh);
    },

    setCurrentArzehWithId: ({commit}, arzeh_id) => {

        axios.get('/bourse/get_one_arzeh/' + arzeh_id)
            .then(({data}) => {
                if(data.status == 'success'){
                    commit('setCurrentArzeh',data.arzeh);
                }
            })
            .catch(e => {
                console.log(e);
            });

    },

    getBankAccountsAyandeh: ({commit}) => {
        axios.get('/bank/get_ayandeh_accounts')
            .then(({data}) => {
                if (data.status == 'success') {
                    commit('setBankAccountAyandeh',data.data);
                }
            })
            .catch(e => {
                this.myAlert('خطا در دریافت اطلاعات حساب های بانکی!', 'error');
            })

    },

    getBankAccountsUsers: ({commit}) => {
        axios.get('/user/bank_account/get_bank_accounts')
            .then(({data}) => {
                if (data.status == 'success') {
                    commit('setBankAccountUser',data.data);
                }
            })
            .catch(e => {
                this.myAlert('خطا در دریافت اطلاعات حساب های بانکی!', 'error');
            })
    },

    getUserAddresses: ({commit}) => {
        axios.get('/user/address/get_addresses')
            .then(({data}) => {
                if (data.status == 'success') {
                    commit('setUserAddresses',data.data);
                }
            })
            .catch(e => {
                this.myAlert('خطا در آدرس های کاربر!', 'error');
            })
    },

    // دارایی های کاربر
    getUserProducts: ({commit}, parameters = {}) => {
        commit('setLoadingUserProducts',true);

        axios.get('/user/product/get_user_products', {params: parameters})
            .then(({data}) => {
                if(data.status == 'success'){
                    commit('setUserProducts',data.data);
                }
                commit('setLoadingUserProducts',false);

            })
            .catch(e => {
                this.myAlert('خطا در دریافت دارایی های کاربر!', 'error');
            })
    },

    // سابقه خرید کاربر / فاکتور های خرید کاربر
    getBuyHistories: ({commit}, parameters = {}) => {
        commit('setLoadingBuyHistory',true);

        axios.get('/user/history/get_history', {params: parameters})
            .then(({data}) => {
                if(data.status == 'success'){
                    commit('setBuyHistories',data.data);
                    commit('setBuyHistoriesTotal',data.total);
                }
                commit('setLoadingBuyHistory',false);

            })
            .catch(e => {
                this.myAlert('خطا در دریافت دارایی های کاربر!', 'error');
            })
    },


    // لیست فروش کاربر
    getSaleProducts: ({commit}, parameters = {}) => {
        commit('setLoadingSaleProducts',true);

        axios.get('/user/sale/get_user_sales',{params : parameters})
            .then(({data}) => {
                if(data.status == 'success'){
                    commit('setSaleProducts',data.data);
                    commit('setSaleProductsTotal',data.total);
                }
                commit('setLoadingSaleProducts',false);
            })
            .catch(e => {
                this.myAlert('خطا در دریافت لیست فروش کاربر!', 'error');
            });
    },


    // دریافت تیکت های کاربر
    getUserTickets: ({commit}, parameters = {}) => {
        commit('setLoadingTickets',true);

        axios.get('/user/ticket/get_tickets', {params: parameters})
            .then(({data}) => {
                if(data.status == 'success'){
                    commit('setUserTickets',data.data);
                    commit('setUserTicketTotal',data.total);
                }
                commit('setLoadingTickets',false);

            })
            .catch(e => {
                this.myAlert('خطا در تیکت های کاربر!', 'error');
            })
    },
}
