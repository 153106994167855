import Vue from "vue";
import VueRouter from "vue-router";
import Master from "@/layouts/Master.vue";
import HomeView from "../views/HomeView.vue";
import About from "../views/About.vue";
import FaqPage from "../views/FaqPage.vue";
import ContactUs from "../views/ContactUs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "Home",
    component: Master,
    children: [
      {
        path: "/",
        name: "Home",
        component: HomeView,
      },
      {
        path: "/about",
        name: "About",
        component: About,
      },
      {
        path: "/faq",
        name: "Faq",
        component: FaqPage,
      },
      // {
      //   path: "/rules",
      //   name: "Rules",
      //   component: About,
      // },
      {
        path: "/contact_us",
        name: "ContactUs",
        component: ContactUs,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;
