<template>
  <div class="home_slider">
    <swiper :pagination="true" :options="swiperOptions">
      <swiper-slide>
        <div
          class="img-slider"
          style="background-image: url(/images/slider/slider1.webp)"
        ></div>
        <div class="container">
          <div class="row m-0">
            <div class="col-12 col-md-6 box-text-slider">
              <h3 class="title-bold-txt">پلتفرم سیمان آینده بروزرسانی شد</h3>
              <button class="btn-a" v-b-modal="'login-modal'">
                ورود به سیستم
              </button>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="img-slider"
          style="background-image: url(/images/slider/slider2.webp)"
        ></div>
        <div class="container">
          <div class="row m-0">
            <div class="col-12 box-text-slider">
              <h3 class="title-bold-txt">آینده سیمان</h3>
              <!-- <p class="title-small">
                بیست و سه محصول جدید در سه دسته بندی به فروشگاه شرکت آینده در
                سال 1401 اضافه گردیده است
              </p>
              <button class="btn-a">اطلاعات بیشتر</button> -->
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="img-slider"
          style="background-image: url(/images/slider/slider3.webp)"
        ></div>
        <div class="container">
          <div class="row m-0">
            <div class="col-12 box-text-slider">
              <h3 class="title-bold-txt">سیمان آینده</h3>
              <!-- <p class="title-small">
                بیست و سه محصول جدید در سه دسته بندی به فروشگاه شرکت آینده در
                سال 1401 اضافه گردیده است
              </p>
              <button class="btn-a">اطلاعات بیشتر</button> -->
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="triangle-top" id="about">
      <div class="swiper-pagination" slot="pagination"></div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox="0 0 2 1"
      >
        <g><path class="triangle-path" d="M0,0 l2,0 l-1,1 z" /></g>
      </svg>
    </div>
    <b-modal id="login-modal" size="lg" centered hide-footer>
      <template #modal-title> ورود به پنل </template>
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between my-3"
      >
        <div class="panel-box">
          <h5 class="text-center mb-3">پنل کاربری</h5>
          <div
            class="image-box position-relative"
            style="background-image: url(/images/new-panel.png)"
            @mouseover="showNewPanelLink = true"
            @mouseleave="showNewPanelLink = false"
          >
            <div
              v-if="showNewPanelLink"
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded"
              style="background-color: rgba(0, 0, 0, 0.2)"
            >
              <b-button
                variant="primary"
                href="https://panel.ayandehcement.com/"
                style="height: 40px"
                >ورود به پنل کاربری</b-button
              >
            </div>
          </div>
        </div>
        <div class="panel-box mt-5 mt-md-0">
          <h5 class="text-center mb-3">پنل باربری</h5>
          <div
            class="image-box position-relative"
            style="background-image: url(/images/old-panel.png)"
            @mouseover="showOldPanelLink = true"
            @mouseleave="showOldPanelLink = false"
          >
            <div
              v-if="showOldPanelLink"
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded cursor-pointer"
              style="background-color: rgba(0, 0, 0, 0.2)"
              @click="goToOldPanel()"
            >
              <b-button
                variant="primary"
                href="https://admin.ayandehcement.com/"
                style="height: 40px"
                >ورود به پنل باربری</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      showNewPanelLink: false,
      showOldPanelLink: false,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    goToOldPanel() {
      window.location.assign("https://opanel.ayandehcement.com/");
    },
    goToNewPanel() {
      window.location.assign("https://panel.ayandehcement.com/");
    },
  },
};
</script>

<style scoped>
.panel-box {
  width: 45%;
  height: auto;
}

.image-box {
  width: 100%;
  min-height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 2px solid #0a3450;
}

@media screen and (max-width: 768px) {
  .panel-box {
    width: 100%;
  }
  .image-box {
    min-height: 150px;
  }
}
</style>
