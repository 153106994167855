<template>
  <aside class="bd-aside right-sidebar d-none d-md-block">
    <ul class="nav nav-pills nav-flush flex-column text-center p-0">
      <sidebar-item
        href-name="Home"
        icon-name="home"
        title="صفحه اصلی"
      ></sidebar-item>
      <!-- <sidebar-item
        href-name="Home"
        icon-name="store"
        title="خرید آنلاین"
      ></sidebar-item> -->
      <sidebar-item
        href-name="Faq"
        icon-name="question_mark"
        title="سوالات متداول"
      ></sidebar-item>
      <sidebar-item
        href-name="ContactUs"
        icon-name="perm_contact_calendar"
        title="ارتباط با ما"
      ></sidebar-item>
      <!-- <sidebar-item
        href-name="About"
        icon-name="error"
        title="درباره ما"
      ></sidebar-item> -->
    </ul>
  </aside>
</template>

<script>
import SidebarItem from "@/components/SidebarItem";
export default {
  components: { SidebarItem },
  methods: {
    toolTipClass(routeName) {
      if (routeName == this.$route.name) {
        return "my-sidebar-tooltip-purple";
      }
      return "my-sidebar-tooltip-yellow";
    },
  },
  computed: {
    // toolTipClass(){
    //
    // }
  },
};
</script>
