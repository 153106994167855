<template>
  <div>
    <ul
      class="faq-content"
      :class="{ 'no-border-last-item': removeBorderLastItem }"
    >
      <li
        class="faq-item"
        v-for="(question, index) in questions"
        :key="index"
        :class="{ show: showIndex === index }"
      >
        <div class="question">
          <div class="d-flex align-items-center">
            <span class="question-icon" @click="showAnswer(index)"></span>
          </div>
          <p
            class="text-purple"
            v-text="question.question"
            @click="showAnswer(index)"
          ></p>
        </div>
        <div class="answer" v-html="question.answer || 'بدون جواب'"></div>
      </li>
      <li
        v-if="questions && questions.length === 0"
        class="list-style-none py-4 bg-gray-light3"
      >
        هیچ سوالی برای نمایش یافت نشد
      </li>
    </ul>
  </div>
</template>

<script>
// import LottieCircle from "./LottieCircle";

export default {
  components: {
    // LottieCircle,
  },
  props: {
    questions: {
      default: () => [],
      type: Array,
    },
    removeBorderLastItem: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showIndex: null,
    };
  },
  methods: {
    showAnswer(index) {
      if (this.showIndex == index) {
        this.showIndex = null;
      } else {
        this.showIndex = index;
      }
    },
  },
};
</script>
