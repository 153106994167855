<template>
  <div class="w-100">
    <slider-home></slider-home>

    <div class="container mt-5 pt-3">
      <div class="row">
        <div class="col-12 col-md-6">
          <h4 class="bold fs-16">فعالیت سیمان آینده</h4>
          <p>
            شرکت سیمان آینده، به عنوان یک تولیدی بدون کارخانه محصولات خود را با
            فرمول های سفارشی توسط کارخانه های سیمان تولید و در کنار محصولات سایر
            کارخانه ها در بازار های داخلی و خارجی عرضه میکند.
          </p>

          <h4
            class="bold fs-14 position-relative mr-5 mr-md-0 my-4 mb-md-0 mt-md-5"
          >
            <div class="square_number_title">
              1
              <lottie-circle></lottie-circle>
            </div>
            خرید آنلاین سیمان
          </h4>
          <p>
            این پلتفرم شما را قادر می سازد تا در کمترین زمان با مناسب ترین قیمت
            سیمان مورد نیاز خود را از کارخانه های سیمان کشور به راحتی تهیه
            نمائید.
          </p>

          <h4
            class="bold fs-14 position-relative mr-5 mr-md-0 my-4 mb-md-0 mt-md-5"
          >
            <div class="square_number_title">
              2
              <lottie-circle></lottie-circle>
            </div>
            پیگیری سفارشات
          </h4>
          <p>
            با استفاده از این پلتفرم می توانید سفارشات خود را از لحظه خرید تا
            دریافت پیگیری نمائید.
          </p>
          <h4
            class="bold fs-14 position-relative mr-5 mr-md-0 my-4 mb-md-0 mt-md-5"
          >
            <div class="square_number_title">
              3
              <lottie-circle></lottie-circle>
            </div>
            مدیریت مالی
          </h4>
          <p>
            در این پلتفرم بررسی صورتحساب های مالی بصورت آنلاین امکان پذیر است.
          </p>
        </div>
        <div class="col-12 col-md-6 ps-3 ps-md-0">
          <img src="./../assets/images/banner-left.png" alt="" width="100%" />
        </div>
      </div>

      <br /><br /><br /><br />

      <div class="row">
        <div class="col-12 text-center">
          <h3 class="title-border-buttom">
            <lottie-circle></lottie-circle>
            چرا باید ما را انتخاب کنید
          </h3>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >receipt_long</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">حواله سریع</h5>
          <p>
            صدور حوالی آنی برای کاربران یکی از بهترین ویژگی های است که شما می
            توانید در هر بار خرید از آن بهره ببرید.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >history_edu</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">اعلام بار</h5>
          <p>
            پس از تکمیل هر خرید حواله بصورت اتوماتیک برای باربری منتخب شما اعلام
            بار می گردد.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >query_builder</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">دسترسی آنلاین</h5>
          <p>
            وضعیت بارهای خریداری شده از سیمان آینده بصورت آنلاین قابل بررسی و
            پیگیری است.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >receipt</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">
            اطلاع رسانی اعلام بار
          </h5>
          <p>
            جهت تسریع در فرآیند بارگیری، اعلام بار شما بصورت سیستمی به اطلاع
            باربری منتخب و رانندگان مرتبط می رسد.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >new_releases</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">تاریخچه کرایه</h5>
          <p>
            شما می توانید با بررسی کرایه های اخیر جزئیات هزینه های حمل را بررسی
            و پیش بینی کنید.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >move_to_inbox</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">
            قیمت های رقابتی
          </h5>
          <p>
            سیمان آینده قیمت های محصولات مختلف را با قیمت مناسب در اختیار شما
            قرار می دهد.
          </p>
        </div>
      </div>

      <br /><br /><br /><br />
    </div>

    <div class="container">
      <div>
        <div class="text-center">
          <h3 class="title-border-buttom">
            <lottie-circle></lottie-circle>
            سوالات متداول
          </h3>
        </div>
        <faq-box :questions="questions"></faq-box>
        <div class="text-center mt-4">
          <router-link :to="{ name: 'Faq' }" class="bold"
            >مشاهده بیشتر</router-link
          >
        </div>
        <br /><br /><br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import SliderHome from "./../components/SliderHome";
import CommentsTopUsers from "./../components/CommentsTopUsers";
import FaqBox from "./../components/FaqBox";
import LottieCircle from "./../components/LottieCircle";

export default {
  components: {
    LottieCircle,
    SliderHome,
    CommentsTopUsers,
    FaqBox,
  },
  data() {
    return {
      questions: [
        {
          question: "پیگیری و هزینه حمل بار با خریدار است؟",
          answer:
            "سیمان آینده مسئولیتی در قبال هماهنگی و ارسال بار ها ندارد و پیگیری حمل بار و هزینه حمل به عهده خریدار می‌باشد.",
        },
        {
          question: "نحوه تحویل بار چگونه است؟",
          answer:
            "پس از تکمیل خرید تناژ خریداری شده بصورت اتوماتیک طی یک کارت برای باربری های منتخب شما اعلام بار می شود.",
        },
        {
          question: "انصراف از خرید امکان پذیر است؟",
          answer:
            "انصراف از خرید تنها در صورت موجود بودن کالا بر روی سایت و عدم تغییر قیمت آن امکان پذیر است.",
        },
        {
          question: "درخواست فاکتور رسمی به چه صورتی است؟",
          answer:
            "<span>برای دریافت فاکتور رسمی طبق قانون جدید استفاده از سامانه موعدیان شما می بایست قبل از تکمیل خرید از قسمت پروفایل یا قسمت صفحه خرید اقدام به احراز هویت نموده و گزینه</span><span class='text-blue-light'> فاکتور رسمی نیاز دارم </span><span>را انتخاب کنید.</span>",
        },
        {
          question: "کاربرد تاریخچه کرایه در پلتفرم سیمان آینده چیست؟",
          answer:
            "با کمک تاریخچه کرایه می توانید نرخ کرایه جاری را بررسی نموده و مطابق نیاز خود کرایه ای بالاتر یا پایین تر از دیگران پیشنهاد دهید",
        },
        {
          question: "منظور از کارت ها و سفارش ها در پلتفرم سیمان آینده چیست؟",
          answer:
            "پس از تکمیل فرآیند خرید، تناژ خریداری شده یک کارت در نظر گرفته می شود و بار های خارج شده یک سفارش در نظر گرفته می شود. شما برای دسترسی به جزئیات هر بخش باید به قسمت مربوطه مراجعه فرمائید.",
        },
        {
          question: "آیا سیمان آینده با برند خود محصولی برای عرضه دارد؟",
          answer:
            "بله، اما محصولات با برند سیمان آینده در حال حاضر فقط در استان سیستان و بلوچستان قابلیت تحویل را دارد.",
        },
      ],
    };
  },
};
</script>
