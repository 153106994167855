<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    $route(val) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style></style>
