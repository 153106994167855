<template>
  <li class="nav-item">
    <router-link :to="{ name: hrefName }" class="item_content">
      <div class="body_icon">
        <span class="material-icons el-center">{{ iconName }}</span>
        <div class="lable_sidebar" :class="classTooltip">
          <div class="arrow"></div>
          <div class="tooltip-inner">{{ title }}</div>
        </div>
      </div>
      <div class="title_menu">{{ title }}</div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    hrefName: String,
    iconName: String,
    title: String,
  },
  computed: {
    classTooltip() {
      if (this.hrefName == this.$route.name) return "my-sidebar-tooltip-purple";
      return "my-sidebar-tooltip-yellow";
    },
  },
};
</script>

<style scoped></style>
