<template>
  <div class="faq-main">
    <div class="text-center">
      <h3 class="title-border-buttom my-4">
        <lottie-circle></lottie-circle>
        {{ title }}
      </h3>

      <div class="my-4">
        <div class="container">
          <faq :questions="questions"></faq>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LottieCircle from "./../components/LottieCircle";
import InputAyandeh from "./../components/form/InputAyandeh";
import Faq from "./../components/Faq";

export default {
  name: "FaqPage",
  components: { Faq, InputAyandeh, LottieCircle },
  data() {
    return {
      categories: null,
      questions: null,
      search: null,
      questions: [
        {
          question: "پیگیری و هزینه حمل بار با خریدار است؟",
          answer:
            "سیمان آینده مسئولیتی در قبال هماهنگی و ارسال بار ها ندارد و پیگیری حمل بار و هزینه حمل به عهده خریدار می‌باشد.",
        },
        {
          question: "نحوه تحویل بار چگونه است؟",
          answer:
            "پس از تکمیل خرید تناژ خریداری شده بصورت اتوماتیک طی یک کارت برای باربری های منتخب شما اعلام بار می شود.",
        },
        {
          question: "انصراف از خرید امکان پذیر است؟",
          answer:
            "انصراف از خرید تنها در صورت موجود بودن کالا بر روی سایت و عدم تغییر قیمت آن امکان پذیر است.",
        },
        {
          question: "درخواست فاکتور رسمی به چه صورتی است؟",
          answer:
            "<span>برای دریافت فاکتور رسمی طبق قانون جدید استفاده از سامانه موعدیان شما می بایست قبل از تکمیل خرید از قسمت پروفایل یا قسمت صفحه خرید اقدام به احراز هویت نموده و گزینه</span><span class='text-blue-light'> فاکتور رسمی نیاز دارم </span><span>را انتخاب کنید.</span>",
        },
        {
          question: "کاربرد تاریخچه کرایه در پلتفرم سیمان آینده چیست؟",
          answer:
            "با کمک تاریخچه کرایه می توانید نرخ کرایه جاری را بررسی نموده و مطابق نیاز خود کرایه ای بالاتر یا پایین تر از دیگران پیشنهاد دهید",
        },
        {
          question: "منظور از کارت ها و سفارش ها در پلتفرم سیمان آینده چیست؟",
          answer:
            "پس از تکمیل فرآیند خرید، تناژ خریداری شده یک کارت در نظر گرفته می شود و بار های خارج شده یک سفارش در نظر گرفته می شود. شما برای دسترسی به جزئیات هر بخش باید به قسمت مربوطه مراجعه فرمائید.",
        },
        {
          question: "آیا سیمان آینده با برند خود محصولی برای عرضه دارد؟",
          answer:
            "بله، اما محصولات با برند سیمان آینده در حال حاضر فقط در استان سیستان و بلوچستان قابلیت تحویل را دارد.",
        },
        {
          answer:
            "شما با وارد کردن شماره تلفن همراه تان  در صورتی که قبلا ثبت نام کرده باشید بعد از دریافت کد یکبار مصرف میتوانید وارد حساب کاربری تان شوید و در غیر این صورت می توانید نام و نام خانوادگی و یک رمز عبور را برای خود وارد کنید و ثبت نام تان را در وب سایت سیمان آینده انجام دهید",
          question: "نحوه ساخت یا ورود  به حساب",
        },
        {
          answer:
            "احتمالا یا موبایل تان در دسترسی نیست یا خطوط پیامکی ما مشغول هستند، شما میتوانید با رمز عبور ثابت تان به جای کد یکبار مصرف وارد وب سایت شوید",
          question: "کد ورود به من پیامک نمی شود",
        },
        {
          answer:
            "در برخی مناطق دسترسی به اینترنت امکان پذیر است اما آنتن دهی وجود ندارد میتوانید از ورود با رمز عبور استفاده کنید.",
          question: "چرا باید رمز عبور ثابتداشته باشیم?",
        },
        {
          question:
            "به اینترنت دسترسی دارم اما آنتن دهی در منطقه من برای دریافت کد مناسب نیست؟",
          answer:
            "احتمالا یا موبایل تان در دسترسی نیست یا خطوط پیامکی ما مشغول هستند، شما میتوانید با رمز عبور ثابت تان به جای کد یکبار مصرف وارد وب سایت شوید",
        },
        {
          question: "چگونه رمز ثابت را تغییر دهم؟",
          answer:
            "در پنل کاربری تان شما می توانید از بخش حساب کاربری اقدام به تغییر رمز عبور تان نمایید",
        },
        {
          question: "رمز ثابت را فراموش کرده ام!",
          answer:
            "در قسمت ورود میتوانید از گزینه فراموشی رمز عبور استفاده کنید.",
        },
      ],
    };
  },
  computed: {
    title() {
      return "سوالات متداول";
    },
  },
};
</script>

<style scoped></style>
